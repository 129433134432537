<template>
  <div class="error-404">
    <div class="content">
      <div class="pic">
        <img :src="require('../assets/images/404-pic.png')" alt="404">
      </div>
      <p class="into-text">抱歉！你请求的页面暂时无法打开…</p>
      <router-link class="btn" :to="{name:'Home'}">返回首页</router-link>
      <el-button class="back-btn" @click="goBack()" type="text">返回上一页</el-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },
}
</script>

<style lang="scss" scoped>
.error-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  .content {
    text-align: center;
    .pic {
      max-width: 371px;
      position: relative;
      &:before {
        content: "";
        display: block;
        padding-bottom: 56.6%;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .into-text {
      margin-top: 35px;
      font-size: 18px;
      line-height: 1;
      color: #303133;
    }
    .btn {
      margin-top: 38px;
      display: inline-block;
      font-size: 18px;
      line-height: 1;
      color: #fff;
      padding: 12px 36px;
      background-color: #3576ff;
      border-radius: 2px;
      text-decoration: none;
      &:hover,
      &:active {
        opacity: 0.7;
      }
    }

    .back-btn {
      margin: 0 auto;
      display: block;
    }
  }
}
</style>